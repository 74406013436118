@use "variables" as v;
@import "reset";
@import "buttons";
@import "form";
@import "redirection-dialog";

html {
  font-family: v.$sans;
  color: v.$white;
  background-color: v.$dark-blue;
}

body {
  padding: 0;
  position: relative;
  background-color: v.$navy-blue;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: v.$navy-blue;
  padding: 7rem 1rem 1rem;
  display: none;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}
.loader {
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #adaeb1;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #adaeb1;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

/*Firefox styles*/
* {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #ffffff #adaeb1; /* scroll thumb and track */
}
