html, body {
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

img {
  display: block;
}

h1, h2, h3 {
  font-size: inherit;
  margin: 0;
  font-weight: normal;
}

input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

select {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: 0;
}

input:focus, select:focus, button:focus {
  outline: none;
}

p {
  margin: 0;
}

.button {
  color: #00084e;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  padding: .625rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  display: block;
}

.button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

#form {
  padding: 1.5rem;
}

#form fieldset {
  margin-bottom: 2rem;
}

#form fieldset:last-child {
  margin-bottom: 0;
}

#form h3 {
  margin-bottom: .75rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
}

#form .terms {
  background-color: #323870;
  width: 100%;
}

#form .terms label {
  align-items: center;
  margin-bottom: 2.625rem;
  display: flex;
  position: relative;
}

#form .terms label a {
  color: #fff;
  margin-left: .5rem;
  line-height: 1.25rem;
  text-decoration: underline;
  display: block;
}

#form .terms label input[type="checkbox"] {
  opacity: 0;
  width: 18px;
}

#form .terms label .custom-checkbox {
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1.5px;
  left: 0;
}

#form .terms label input[type="checkbox"]:checked ~ .custom-checkbox {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMy44LDlBMSwxLDAsMCwxLDUuMjIsOWwyLjE0LDIuMSw2LjQyLTYuMzFhMSwxLDAsMCwxLDEuNDIsMCwxLDEsMCwwLDEsMCwxLjRsLTcuMTMsN2ExLDEsMCwwLDEtMS40MiwwTDMuOCwxMC40QTEsMSwwLDAsMSwzLjgsOVoiIC8+PC9zdmc+Cg==") center no-repeat;
}

#form .button {
  float: right;
  min-width: 7.5rem;
}

#form .button .loader {
  border: 2px solid rgba(50, 56, 112, .2);
  border-left-color: #323870;
  width: 1rem;
  height: 1rem;
  margin: auto;
  font-size: 2px;
  display: none;
}

.form-input {
  height: 3.5rem;
  position: relative;
}

.form-input input {
  background-color: #323870;
  border-bottom: 1px solid #d4d6ed;
  border-radius: 0;
  width: 100%;
  height: 3.5rem;
  padding: 1.75rem .5rem .5rem;
  font-weight: 700;
  line-height: 1.25rem;
  display: block;
}

.form-input input:disabled {
  color: #fff;
  opacity: .4;
}

.form-input input.error {
  border-bottom: 2px solid #ff5800;
}

.form-input select {
  background-color: #323870;
  border-radius: 0;
  width: 100%;
  padding: 1.75rem .5rem .5rem;
  font-weight: 700;
  display: block;
}

.form-input label {
  box-sizing: border-box;
  pointer-events: none;
  width: 100%;
  padding: 0 .5rem;
  font-size: 1rem;
  line-height: 1.125rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.form-input label.error {
  color: #ff5800;
}

.form-input input ~ label, .form-input select ~ label {
  transition: top .15s;
  top: 1.2rem;
}

.form-input input:disabled, .form-input select:disabled {
  color: #fff;
  opacity: .4;
}

.form-input input:disabled ~ label, .form-input input:valid ~ label, .form-input input:focus ~ label, .form-input select:disabled ~ label, .form-input select:valid ~ label, .form-input select:focus ~ label {
  font-size: .75rem;
  line-height: .875rem;
  top: .625rem;
}

#payment_code_invalid, #cardholder_invalid {
  color: #ff5800;
  margin: .25rem .5rem;
  font-size: .875rem;
  line-height: 1rem;
  display: none;
}

#ena_info {
  color: #d8d8d8;
  opacity: .4;
  margin: .25rem .5rem;
  font-size: .875rem;
  line-height: 1rem;
  display: none;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 0 30px #323870 !important;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 0 30px #323870 !important;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 0 30px #323870 !important;
}

input:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 0 30px #323870 !important;
}

select:-webkit-autofill {
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 0 30px #323870 !important;
}

select:-webkit-autofill:hover {
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 0 30px #323870 !important;
}

select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  box-shadow: inset 0 0 0 30px #323870 !important;
}

select {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background: #323870;
  border: 0;
  outline: 0;
}

select::-ms-expand {
  display: none;
}

.select {
  background: #323870;
  border-radius: 0;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

select {
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #d4d6ed;
  flex: 1;
  line-height: 1.25rem;
}

.select:after {
  content: "";
  cursor: pointer;
  pointer-events: none;
  -o-transition: .25s all ease;
  background: #323870 url("arrowDown.a53dbf13.png") 0 0 / 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: all .25s;
  position: absolute;
  top: 1rem;
  right: .5rem;
}

.select:hover:after {
  color: #545786;
}

.icons {
  justify-content: flex-end;
  align-items: flex-start;
  margin: -1rem 0 2rem;
  display: flex;
}

.icons img {
  margin: 0 0 0 .5rem;
}

.error-box {
  background-color: #545786;
  border-radius: .25rem;
  align-items: flex-start;
  column-gap: .5rem;
  margin: 0 0 1.5rem;
  padding: 1rem;
  display: none;
}

.error-box .error-icon, .error-box button {
  flex: 0 0 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.error-box button:hover {
  opacity: .6;
}

.error-box .error-description {
  flex: 1;
}

.error-box .error-description h4 {
  margin: 0 0 .5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.bank-redirection-dialog {
  z-index: 100;
  background-color: #323870;
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: max-content;
  max-height: max-content;
  margin: auto;
  padding: 1.5rem;
  display: none;
  position: absolute;
  inset: 0 1.5rem;
}

.bank-redirection-dialog .info-text {
  color: #fff;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
}

.bank-redirection-dialog-overlay {
  z-index: 99;
  background-color: rgba(0, 8, 78, .9);
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  inset: 0;
}

html {
  color: #fff;
  background-color: #00084e;
  font-family: Manrope, sans-serif;
}

body {
  background-color: #323870;
  padding: 0;
  position: relative;
}

.loader-wrapper {
  background-color: #323870;
  justify-content: center;
  align-items: flex-start;
  padding: 7rem 1rem 1rem;
  display: none;
  position: absolute;
  inset: 0;
}

.loader, .loader:after {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.loader {
  text-indent: -9999em;
  border: 3px solid rgba(255, 255, 255, .2);
  border-left-color: #fff;
  font-size: 4px;
  animation: 1.1s linear infinite load8;
  position: relative;
  transform: translateZ(0);
}

@keyframes load8 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar-track {
  background-color: #adaeb1;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

::-webkit-scrollbar {
  background-color: #adaeb1;
  border-radius: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fff #adaeb1;
}

/*# sourceMappingURL=index.0740e24a.css.map */
