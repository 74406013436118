.bank-redirection-dialog {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;

  background-color: v.$navy-blue;

  max-width: max-content;
  max-height: max-content;
  padding: 1.5rem;
  border-radius: 1rem;


  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

}

.bank-redirection-dialog .info-text {
  color: v.$white;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: 700;
}

.bank-redirection-dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(v.$dark-blue, 0.9);
  display: none;
}