html,
body {
	padding: 0;
	margin: 0;
}

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

button {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

img {
	display: block;
}

h1,
h2,
h3 {
	font-size: inherit;
	font-weight: normal;
	margin: 0;
}

input {
	border: 0;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
select {
	border: 0;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}
input:focus,
select:focus,
button:focus {
	outline: none;
}

p {
	margin: 0;
}
