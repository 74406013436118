.button {
  display: block;
  border-radius: 4px;
  background-color: v.$white;
  padding: 0.625rem 1rem;
  color: v.$dark-blue;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
