// Colors
$white: #fff;
$disabled-text: #d8d8d8;
$grey: #adaeb1;
$dark-blue: #00084e;
$navy-blue: #323870;
$navy-blue-light: #545786;
$red: #ff5800;
$separator: #d4d6ed;

// Fonts
$sans: "Manrope", sans-serif;
