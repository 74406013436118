#form {
  padding: 1.5rem;

  fieldset {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.75rem;
    font-weight: 700;
  }

  .terms {
    width: 100%;
    background-color: v.$navy-blue;

    label {
      position: relative;
      display: block;
      display: flex;
      align-items: center;
      margin-bottom: 2.625rem;

      a {
        display: block;
        line-height: 1.25rem;
        text-decoration: underline;
        margin-left: 0.5rem;
        color: v.$white;
      }

      input[type="checkbox"] {
        opacity: 0;
        width: 18px;
      }

      .custom-checkbox {
        width: 18px;
        height: 18px;
        border: 1px solid v.$white;
        box-sizing: border-box;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 1.5px;
        cursor: pointer;
      }

      input[type="checkbox"]:checked ~ .custom-checkbox {
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMy44LDlBMSwxLDAsMCwxLDUuMjIsOWwyLjE0LDIuMSw2LjQyLTYuMzFhMSwxLDAsMCwxLDEuNDIsMCwxLDEsMCwwLDEsMCwxLjRsLTcuMTMsN2ExLDEsMCwwLDEtMS40MiwwTDMuOCwxMC40QTEsMSwwLDAsMSwzLjgsOVoiIC8+PC9zdmc+Cg==)
          no-repeat center center;
      }
    }
  }

  .button {
    float: right;
    min-width: 7.5rem;

    .loader {
      border: 2px solid rgba(v.$navy-blue, 0.2);
      border-left: 2px solid v.$navy-blue;
      font-size: 2px;
      width: 1rem;
      height: 1rem;
      display: none;
      margin: auto;
    }
  }
}

.form-input {
  position: relative;
  height: 3.5rem;

  input {
    display: block;
    width: 100%;
    height: 3.5rem;
    background-color: v.$navy-blue;
    border-bottom: 1px solid v.$separator;
    font-weight: 700;
    line-height: 1.25rem;
    padding: 1.75rem 0.5rem 0.5rem;
    border-radius: 0;

    &:disabled {
      color: v.$white;
      opacity: 0.4;
    }

    &.error {
      border-bottom: 2px solid v.$red;
    }
  }

  select {
    display: block;
    width: 100%;
    background-color: v.$navy-blue;
    padding: 1.75rem 0.5rem 0.5rem;
    font-weight: 700;
    border-radius: 0;
  }

  label {
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 0 0.5rem;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;

    &.error {
      color: v.$red;
    }
  }

  input,
  select {
    ~ label {
      top: 1.2rem;
      transition: top 0.15s;
    }
    &:disabled {
      color: v.$white;
      opacity: 0.4;
    }
    &:disabled ~ label,
    &:valid ~ label,
    &:focus ~ label {
      top: 0.625rem;
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }
}

#payment_code_invalid,
#cardholder_invalid {
  font-size: 0.875rem;
  line-height: 1rem;
  color: v.$red;
  display: none;
  margin: 0.25rem 0.5rem;
}

#ena_info {
  font-size: 0.875rem;
  line-height: 1rem;
  display: none;
  margin: 0.25rem 0.5rem;
  color: v.$disabled-text;
  opacity: 0.4;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 30px v.$navy-blue inset !important;
  -webkit-box-shadow: 0 0 0 30px v.$navy-blue inset !important;
  -webkit-text-fill-color: #fff;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0;
  background: v.$navy-blue;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 100%;
  background: v.$navy-blue;
  overflow: hidden;
  border-radius: 0;
}

select {
  flex: 1;
  color: #fff;
  cursor: pointer;
  line-height: 1.25rem;
  border-bottom: 1px solid v.$separator;
}
/* Arrow */
.select::after {
  content: "";
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  background: v.$navy-blue;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
  background-image: url("/assets/arrowDown.png");
  background-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

/* Transition */
.select:hover::after {
  color: v.$navy-blue-light;
}

.icons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin: -1rem 0 2rem;

  img {
    margin: 0 0 0 0.5rem;
  }
}

.error-box {
  //display: flex;
  background-color: v.$navy-blue-light;
  border-radius: 0.25rem;
  padding: 1rem;
  column-gap: 0.5rem;
  margin: 0 0 1.5rem;
  align-items: flex-start;
  display: none;

  .error-icon {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

  button {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:hover {
      opacity: 0.6;
    }
  }

  .error-description {
    flex: 1;

    h4 {
      margin: 0 0 0.5rem;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: 600;
    }
  }
}
